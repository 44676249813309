.activityCommentsBarContainer__delete{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 1em;
}
.activityCommentsBarContainer__delete img{
    width: 1em;
    height: 1em;
    cursor: pointer;
}
