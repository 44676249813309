@import "../../sass/colors.scss";

.accordionContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 1em 1em 1em 0;
    cursor: pointer;

    &--compressed {
        padding: 0.3em 1em 0.3em 0;
    }

    &:hover {
      outline: solid 1px $secondary-30-color;
      border: none;
    }
  }

  &__icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 1em;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__actionBar {
    margin-left: auto;
  }

  &__divider {
    margin-right: 1em;
  }

  &__chevron {
    transition: transform 0.2s ease-out 0s;

    &--right {
      margin-left: auto;
    }

    &--close {
      transform: rotate(180deg);
    }
  }

  &__content {
    width: 100%;
    padding: 1px 1px;
    overflow: hidden;
    transition: height 0.2s ease-out;

    &--open {
      height: 100%;
    }

    &--close {
      height: 0;
      padding: 0 1px;
    }
  }
}
