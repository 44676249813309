@font-face {
  font-family: 'basier_squareregular';
  src: url('https://cdn.soteria.io/fonts/basiersquare-regular-webfont.eot');
  src: url('https://cdn.soteria.io/fonts/basiersquare-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.soteria.io/fonts/basiersquare-regular-webfont.woff2') format('woff2'),
  url('https://cdn.soteria.io/fonts/basiersquare-regular-webfont.woff') format('woff'),
  url('https://cdn.soteria.io/fonts/basiersquare-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'basier_squaremedium';
  src: url('https://cdn.soteria.io/fonts/basiersquare-medium-webfont.eot');
  src: url('https://cdn.soteria.io/fonts/basiersquare-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.soteria.io/fonts/basiersquare-medium-webfont.woff2') format('woff2'),
  url('https://cdn.soteria.io/fonts/basiersquare-medium-webfont.woff') format('woff'),
  url('https://cdn.soteria.io/fonts/basiersquare-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
