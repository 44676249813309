@import "../../sass/colors.scss";

.organizationContainer {
  &__info {
    display: grid;
    grid-template-areas:
      "organizationContainer__details"
      "organizationContainer__configuration"
      "organizationContainer__users";
    row-gap: 4em;
  }

  &__header__divider {
    margin-left: 1em;
    margin-right: 1em;
  }

  &__details {
    grid-area: organizationContainer__details;
    display: grid;
    grid-template-columns: 34% 66%;
    grid-template-areas: "organizationContainer__details_title organizationContainer__details__info";
    padding-top: 1.5em;
    padding-left: 1em;
    padding-right: 1em;

    &__title {
      grid-area: organizationContainer__details_title;
    }
    &__info:not(:has(*)) {
      display: none;
    }
    &__info {
      grid-area: organizationContainer__details__info;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "organizationContainer__details__info__name organizationContainer__details__info__name"
        "organizationContainer__details__info__timezone organizationContainer__details__info__region"
        "organizationContainer__details__info__rules organizationContainer__details__info__rules"
        "organizationContainer__details__info__escalationName organizationContainer__details__info__escalationName"
        "organizationContainer__details__info__escalationEmail organizationContainer__details__info__escalationPhone"
        "organizationContainer__details__info__handler organizationContainer__details__info__handlerEmail"
        "organizationContainer__details__info__handlerPhone organizationContainer__details__info__connection";
      gap: 3em 3em;

      &.isMDRUser {
        grid-template-areas:
          "organizationContainer__details__info__name organizationContainer__details__info__name"
          "organizationContainer__details__info__description organizationContainer__details__info__description"
          "organizationContainer__details__info__timezone organizationContainer__details__info__region"
          "organizationContainer__details__info__rules organizationContainer__details__info__rules"
          "organizationContainer__details__info__escalationName organizationContainer__details__info__escalationName"
          "organizationContainer__details__info__escalationEmail organizationContainer__details__info__escalationPhone"
          "organizationContainer__details__info__handler organizationContainer__details__info__handlerEmail"
          "organizationContainer__details__info__handlerPhone organizationContainer__details__info__connection";
      }

      &.isCreating {
        grid-template-areas:
          "organizationContainer__details__info__name organizationContainer__details__info__name"
          "organizationContainer__details__info__description organizationContainer__details__info__description"
          "organizationContainer__details__info__timezone organizationContainer__details__info__region"
          "organizationContainer__details__info__rules organizationContainer__details__info__rules"
          "organizationContainer__details__info__escalationName organizationContainer__details__info__escalationName"
          "organizationContainer__details__info__escalationEmail organizationContainer__details__info__escalationPhone"
          "organizationContainer__details__info__handler organizationContainer__details__info__connection";

        &.isClient {
          grid-template-areas:
            "organizationContainer__details__info__name organizationContainer__details__info__name"
            "organizationContainer__details__info__rules organizationContainer__details__info__rules"
            "organizationContainer__details__info__escalationName organizationContainer__details__info__escalationName"
            "organizationContainer__details__info__escalationEmail organizationContainer__details__info__escalationPhone"
            "organizationContainer__details__info__handler .";
        }
      }
      &.isClient {
        grid-template-areas:
          "organizationContainer__details__info__name organizationContainer__details__info__name"
          "organizationContainer__details__info__rules organizationContainer__details__info__rules"
          "organizationContainer__details__info__escalationName organizationContainer__details__info__escalationName"
          "organizationContainer__details__info__escalationEmail organizationContainer__details__info__escalationPhone"
          "organizationContainer__details__info__handler organizationContainer__details__info__handlerEmail"
          "organizationContainer__details__info__handlerPhone .";
      }

      &__name {
        grid-area: organizationContainer__details__info__name;
      }

      &__description {
        grid-area: organizationContainer__details__info__description;
      }

      &__timezone {
        grid-area: organizationContainer__details__info__timezone;
      }

      &__region {
        grid-area: organizationContainer__details__info__region;
      }

      &__rules {
        grid-area: organizationContainer__details__info__rules;
      }

      &__escalationName {
        grid-area: organizationContainer__details__info__escalationName;
      }

      &__escalationEmail {
        grid-area: organizationContainer__details__info__escalationEmail;
      }

      &__escalationPhone {
        grid-area: organizationContainer__details__info__escalationPhone;
      }

      &__connection {
        grid-area: organizationContainer__details__info__connection;
      }

      &__handler {
        grid-area: organizationContainer__details__info__handler;
      }

      &__handlerEmail {
        grid-area: organizationContainer__details__info__handlerEmail;
      }

      &__handlerPhone {
        grid-area: organizationContainer__details__info__handlerPhone;
      }
    }
  }

  &__configuration {
    grid-area: organizationContainer__configuration;
    display: grid;
    grid-template-columns: 34% 66%;
    grid-template-areas: "organizationContainer__configuration__title organizationContainer__configuration__info";
    padding-top: 1.5em;
    padding-left: 1em;
    padding-right: 1em;
    border-top: 1px solid $secondary-50-color;

    &__title {
      grid-area: organizationContainer__configuration__title;
    }

    &__info {
      grid-area: organizationContainer__configuration__info;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "organizationContainer__configuration__edr organizationContainer__configuration__quota";
      gap: 3em 3em;

      &__edr {
        grid-area: organizationContainer__configuration__edr;
      }

      &__quota {
        grid-area: organizationContainer__configuration__quota;
      }

      &__provider {
        &__text {
          margin-top: 0.5em;
        }
      }
    }
  }

  &__users {
    grid-area: organizationContainer__users;
    display: grid;
    grid-template-columns: 34% 66%;
    grid-template-areas: "organizationContainer__users__title organizationContainer__users__info";
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 1em;
    padding-right: 1em;
    border-top: 1px solid $secondary-50-color;

    &__title {
      grid-area: organizationContainer__users__title;
    }

    &__info {
      grid-area: organizationContainer__users__info;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &__text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        & svg {
          width: 25px;
        }

        &__value {
          margin-top: 0.5em;
        }
      }

      &__button {
        margin-left: auto;
      }
    }
  }
}
