
.eventContainer__action-bookmark, .eventContainer__action-edrView,.eventContainer__action-details{
    position: relative;
    width: 1em;
    height: 1em;
    margin-right: 1em;
    cursor: pointer;
}

.eventContainer__action-bookmark img, .eventContainer__action-edrView img, .eventContainer__action-details img{
    width: 1em;
    height: 1em;
}

.eventContainer__action-bookmark:hover img, .eventContainer__action-edrView:hover img, .eventContainer__action-details:hover img{
    filter: brightness(0) saturate(100%) invert(47%) sepia(12%) saturate(3411%) hue-rotate(170deg) brightness(87%) contrast(84%);
}
