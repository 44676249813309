@import "../../sass/colors.scss";

.textAreaContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  label {
    margin-bottom: 0.5em;
  }

  .read-only {
    overflow-wrap: anywhere;
    padding: 10px;
  }

  &__text {
    resize: none;
    border: none;
    padding: 0.5em;
    background-color: $secondary-10-color;

    &::placeholder {
      opacity: 0.4;
    }

    &:hover {
      outline: solid 1px $secondary-30-color;
      border: none;
    }

    &:focus {
      outline: none;
      border: none;
      background-color: $secondary-20-color;
    }

    &--heightDefault {
      height: 8em;
    }

    &--widthDefault {
      width: 18em;
    }

    &--widthMax {
      width: 100%;
    }

    &--error {
      color: $status-high-100-color;
      border: solid 1px $status-high-100-color;
    }
  }

  &--widthDefault {
    width: 18em;
  }

  &--widthMax {
    width: 100%;
  }

  &__error {
    color: $status-high-100-color;
    margin-top: 3px;
    bottom: -20px;
    left: 10px;
    font-size: 0.7em;
  }
}
