.incidentContainer__header__assign{
    position: relative;
}
.incidentContainer__header__assign .menu-container__list {
    font-size: 13px;
}

.incidentContainer__header__tooltip__text{
    white-space: nowrap;
}

.incidentContainer__header__assign__anchor{
    position: relative;
    top: 1em;
}

.incidentContainer__header__actionBar__review {
    min-width: 16em;
}
