@import "../../sass/colors.scss";

.sensorsListfilters__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 1em 2em;
  padding-top: 1.5em;
  border-top: solid 1px $secondary-50-color;

  &--hide {
    display: none;
  }

  .filters {
    display: flex;
    column-gap: 1.5em;
    flex-basis: 90%;
    justify-content: space-between;

    .valueFilters,
    .dateFilters {
      display: flex;
    }

    .valueFilters {
      column-gap: 1.5em;
      flex-basis: 60%;

      .osFilter, .tagsFilter, .statusFilter {
        flex-basis: calc(50% - 1.2em);
        max-width: 300px;
      }
    }

    .dateFilters {

      align-items: flex-end;
      flex-basis: 40%;
      justify-content: flex-end;

      .lastConnectionStartDateFilter {
        flex-basis: calc(20% - 1.5em);
        z-index: 1;
      }

      .lastConnectionEndDateFilter {
        flex-basis: calc(20% - 1.5em);
        z-index: 1;
      }

      &__divider {
        background-color: $secondary-10-color;
        width: 25px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-symbol {
          width: 12px;
          height: 2px;
          background-color: $secondary-50-color;
        }
      }
    }
  }

  .filterActions {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin-top: 15px;
    flex-basis: 10%;
  }
}
