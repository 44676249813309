@import "../../sass/colors.scss";

.settingsContainer {
  display: flex;
  flex-direction: column;
  background-color: $secondary-05-color;

  .settingsDelete {
    margin-left: 1.2em;
    cursor: pointer;
  }

  .settingsActionsDivider {
    margin-left: 1.2em;
  }
}

.securityContainer {
  border-top: solid 1px #83a6b5;

  .containerInfo {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .securityOption {
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.profileContainer,
.securityContainer {
  margin-bottom: 50px;

  display: grid;
  grid-template-columns: 34% 66%;
  grid-template-areas: "containerTitle containerInfo";
  padding-top: 1.5em;
  padding-left: 1em;
  padding-right: 1em;

  .containerTitle {
    grid-area: containerTitle;
  }

  .containerInfo {
    grid-area: containerInfo;

    .containerDivider {
      grid-area: profileDivider;
      border-top: 1px solid $secondary-30-color;
    }
  }
}

.profileContainer {
  .containerInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "profileFirstNameInput profileLastNameInput"
      "profilePhoneNumber1Input profilePhoneNumber2Input"
      "profileEmailInput ."
      "profileDivider profileDivider"
      "profileRole .";
    gap: 3em 3em;

    label {
      font-size: 0.75em;
    }

    .profileFirstNameInput {
      grid-area: profileFirstNameInput;
    }

    .profileLastNameInput {
      grid-area: profileLastNameInput;
    }

    .profilePhoneNumber1Input {
      grid-area: profilePhoneNumber1Input;
    }

    .profilePhoneNumber2Input {
      grid-area: profilePhoneNumber2Input;
    }

    .profileEmailInput {
      grid-area: profileEmailInput;
    }

    .profileRole {
      grid-area: profileRole;
    }
  }
}
