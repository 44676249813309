@import "./colors.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  background-color: $secondary-05-color;
  color: $secondary-100-color;
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  min-height: 100vh;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: normal;
}

h2 {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 40px;
}

h3 {
  font-family: "basier_squaremedium", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
}

h4 {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
}

h5 {
  font-family: "basier_squaremedium", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
}

label {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
}

input {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
}

textArea {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
}

.noDecoration {
  text-decoration: none;
  color: $secondary-100-color;
}

.avatar-with-name {
  display: flex;
  align-items: center;

  span {
    margin-left: 15px;
  }
}

.tooltip__text {
  text-transform: capitalize;
}

.hide {
  display: none;
}

.indent {
  margin-left: 10px;
}

.margin-top {
  margin-top: 10px;
}

.has-tooltip:hover {
  position: relative;
  .tooltipContainer {
    display: flex;
  }
}
