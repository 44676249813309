.incidentContainer {
  &__mdrUser,
  &__clientUser {
    display: grid;
    grid-template-areas:
      "incidentContainer__header               incidentContainer__header               incidentContainer__header                incidentContainer__header"
      "incidentContainer__banner               incidentContainer__banner               incidentContainer__banner                incidentContainer__banner"
      "incidentContainer__details__header      incidentContainer__details__header      .                                        incidentContainer__activity"
      "incidentContainer__details__created     incidentContainer__details__status      .                                        incidentContainer__activity"
      "incidentContainer__details__category    incidentContainer__details__severity    .                                        incidentContainer__activity"
      "incidentContainer__details__hostname    incidentContainer__details__username    .                                        incidentContainer__activity"
      "incidentContainer__details__title       incidentContainer__details__title       .                                        incidentContainer__activity"
      "incidentContainer__details__description incidentContainer__details__description .                                        incidentContainer__activity"
      "incidentContainer__details__tags        incidentContainer__details__tags        .                                        incidentContainer__activity"
      "incidentContainer__details__save        incidentContainer__details__save        .                                        incidentContainer__activity"
      ".                                       .                                       .                                        incidentContainer__activity";

    grid-template-rows: repeat(10, auto) 1fr;

    &--collapsed {
      display: grid;
      grid-template-areas:
        "incidentContainer__header                      incidentContainer__header               incidentContainer__header"
        "incidentContainer__details__header-collapse    incidentContainer__division__header     incidentContainer__activity"
        "incidentContainer__details__status-collapse    incidentContainer__division__status     incidentContainer__activity"
        "incidentContainer__details__category-collapse  incidentContainer__division__category   incidentContainer__activity"
        "incidentContainer__details__severity-collapse  incidentContainer__division__severity   incidentContainer__activity"
        "incidentContainer__details__more-collapse      incidentContainer__division__more       incidentContainer__activity"
        ".                                              incidentContainer__division__more       incidentContainer__activity";
      grid-template-rows: repeat(6, auto) 1fr;
    }
  }

  &--width-default {
    grid-template-columns: 17% 17% 1px calc(66% - 1px);
  }

  &--width-collapsed {
    grid-template-columns: 6em 1px calc(100% - 6em - 1px);
  }
}
