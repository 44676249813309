@import "../../sass/colors.scss";

.input-container {
  position: relative;
  height: 52px;

  & > .input {
    width: 100%;
    height: 52px;
    border-radius: 3px;
    border: 1px solid #c2c8d0;
    transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 15px;
    outline: none;

    &:focus {
      border-color: $accent-new-100-color;
      box-shadow: 0 0 0 1px $accent-new-100-color;
    }

    &:focus + .placeholder,
    &.valid + .placeholder {
      color: $accent-new-100-color;
      transform: scale(0.9) translateX(-8px) translateY(-50%);
      top: -3px;
      left: 10px;
      padding: 0 6px;
    }

    &.valid:not(:focus) + .placeholder {
      color: #6f7780;
    }
  }

  &.error {
    & > .input {
      border-color: #dc3545;

      &:focus {
        box-shadow: 0 0 0 1px #dc3545;
      }

      &:focus + .placeholder,
      &:not(:focus) + .placeholder {
        color: #dc3545;
      }
    }

    & > .error {
      display: block;
    }

    & > .placeholder {
      color: $accent-new-100-color;
      transform: scale(0.9) translateX(-8px) translateY(-50%);
      top: -3px;
      left: 10px;
      padding: 0 6px;
    }
  }

  & > .placeholder {
    position: absolute;
    top: 15px;
    left: 21px;
    font-size: 16px;
    color: #6f7780;
    background-color: $secondary-00-color;
    transition: transform 0.15s ease-in-out, top 0.15s ease-in-out,
      color 0.15s ease-in-out, left 0.15s ease-in-out, padding 0.15s ease-in-out;
  }
  & > .error {
    color: #dc3545;
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    left: 5px;
    display: none;
  }
}
