@import "../../sass/colors.scss";

.codeBlockContainer {
  position: relative;
  background-color: $secondary-10-color;
  padding: 10px;

  &__copy {
    position: absolute;
    margin-left: auto;
    height: 1em;
    top: 20px;
    right: 30px;

    img {
      height: 1em;
      cursor: pointer;

      &:hover {
        filter: brightness(0) saturate(100%) invert(47%) sepia(12%)
          saturate(3411%) hue-rotate(170deg) brightness(87%) contrast(84%);
      }
    }
  }

  &__text {
    font-family: monospace;
    line-height: normal;
    height: 5em;
    word-break: break-all;
    overflow-y: scroll;
    white-space: pre-line;
    padding-right: 50px;

    scrollbar-color: $secondary-100-color transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;

      &-thumb {
        background: $secondary-100-color;
        border-radius: 4px;
      }
    }
  }
}
