@import "../../sass/colors.scss";

.skeleton {
  margin: 5px 0;
  background-color: #a7c4cf;
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: skeleton 2s ease-in-out infinite;

  &-shortText {
    width: 40%;
    height: 10px;
  }
  &-text {
    width: 100%;
    height: 10px;
  }
  &-avatar {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }
  &-chip {
    width: 60px;
    height: 25px;
    border-radius: 15px;
  }
  &-check {
    width: 15px;
    height: 15px;
    border-radius: 5px;
  }
  &-chip {
    width: 60px;
    height: 25px;
    border-radius: 15px;
  }
  &-input {
    width: 100%;
    height: 40px;
  }
  &-textArea {
    width: 100%;
    height: 128px;
  }
  &-select {
    width: 100%;
    height: 38px;
  }
  &-title {
    width: 200px;
    height: 20px;
  }
  &-subtitle {
    width: 200px;
    height: 10px;
  }
}

.event-skeleton {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__accordion {
    display: flex;
    gap: 20px;

    &__header {
      display: flex;
      flex-direction: column;

      &__subtitle {
        width: 70%;
      }
    }
  }

  &__content {
    display: flex;
    gap: 20px;

    &__line {
      width: 2px;
      margin-left: 13px;
    }

    &__data {
      width: 70%;

      &__label {
        width: 15%;
      }
    }
  }
}

@keyframes skeleton {
  50% {
    opacity: 0.2;
  }
}
