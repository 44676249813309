@import "../../sass/colors.scss";

.incidentsContextMenuContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &__icon {
    padding-left: 1em;
    padding-right: 1em;
  }
}
