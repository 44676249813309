@import "../../sass/colors.scss";

.button{
    font-size: 1em;
    height: 2.5em;
    padding: 0.7em;
    border:none;
    color: $secondary-100-color;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.button:disabled {
    cursor: not-allowed;
}

.button__primary img, .button__secondary img, .button__primary svg, .button__secondary svg {
    height: 1.25em;
}

.button__primary:not(.button--iconColor) img, .button__primary:not(.button--iconColor) svg {
    filter: brightness(0) saturate(100%) invert(100%) sepia(49%) saturate(2246%) hue-rotate(178deg) brightness(106%) contrast(98%);
}

.button__secondary:not(.button--iconColor) img, .button__secondary:not(.button--iconColor) svg {
    filter: brightness(0) saturate(100%) invert(12%) sepia(32%) saturate(2633%) hue-rotate(174deg) brightness(92%) contrast(103%);
}

.button__primary{
    background-color: $accent-new-100-color;
    color: $accent-new-10-color;
}

.button__primary:not(.loading):hover{
    background-color: $accent-new-120-color;
}

.button__primary:not(.loading):disabled {
    background-color: $secondary-20-color;
    color: $secondary-50-color;
}

.button__primary.active {
    background-color: $secondary-100-color;
}

.button__secondary, .button__transparent{
    background-color: transparent;
}

.button__secondary:not(.loading):disabled {
    color: $secondary-40-color;
}

.button:disabled:not(.loading) img, .button:not(.loading):disabled svg {
    filter: brightness(0) saturate(100%) invert(91%) sepia(4%) saturate(2243%) hue-rotate(164deg) brightness(94%) contrast(71%);
}

.button__secondary.active {
    color: $accent-new-120-color;
}

.button__secondary.active img, .button__secondary.active svg {
    filter: brightness(0) saturate(100%) invert(36%) sepia(91%) saturate(595%) hue-rotate(174deg) brightness(95%) contrast(82%);
}

.button__secondary:not(:disabled):not(.loading):hover{
    background-color: $secondary-10-color;
}

.button__transparent {
    cursor: none;
}
