@import "../../sass/colors.scss";

.incidentContainer__activity__addComments{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.incidentContainer__activity__addComments__avatar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 2em;
    margin-right: 1em;
}

.incidentContainer__activity__addComments__loading {
    position: absolute;
    right: 0;   
}