.incidentContainer__details__header-collapse{
    grid-area: incidentContainer__details__header-collapse;
    margin: 2em 1em 0 1em;
    padding-bottom:3em;
}

.incidentContainer__details__header-collapse > :nth-child(1){
    cursor: pointer;
    width: 1.5em;
}

.incidentContainer__details__status-collapse{
    grid-area: incidentContainer__details__status-collapse;
    margin: 1em 1em 0 1em;
    padding-bottom: 1.5em;
    cursor: pointer;
}

.incidentContainer__details__category-collapse{
    grid-area: incidentContainer__details__category-collapse;   
    margin: 0 1em 1em 1em;
    cursor: pointer;
}

.incidentContainer__details__severity-collapse{
    grid-area: incidentContainer__details__severity-collapse;    
    margin: 0 1em 0 1em;
    padding-bottom: 1.5em;
    cursor: pointer;
}

.incidentContainer__details__more-collapse{
    grid-area: incidentContainer__details__more-collapse;
    margin: 0 1em 0 1em;
    cursor: pointer;
}

.incidentContainer__details__section--collapse{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}
