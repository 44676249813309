@import "../../sass/colors.scss";

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  label {
    margin-bottom: 0.5em;
  }

  &__readonly-value {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &--widthDefault {
    width: 18em;
  }

  &--widthMax {
    width: 100%;
  }

  &__input {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: $secondary-10-color;

    &:focus-within {
      background-color: $secondary-20-color;
    }

    &--widthDefault {
      width: 18em;
    }

    &--widthMax {
      width: 100%;
    }

    &__icon {
      margin-left: 0.5em;
    }

    &__text {
      border: none;
      font-size: 1em;
      height: 2.5em;
      width: 100%;
      padding: 0.5em;
      background-color: $secondary-10-color;

      &.date-input {
        position: absolute;
        background-color: transparent;
        width: calc(100% - 30px);

        &:focus {
          background-color: transparent !important;
        }
      }

      &.date-input + & {
        color: transparent !important;
      }

      &--error {
        color: $status-high-100-color;
        border: solid 1px $status-high-100-color;
      }

      &::placeholder {
        opacity: 0.4;
      }

      &:disabled {
        outline: none;
        border: none;
      }

      &:focus {
        outline: none;
        border: none;
        background-color: $secondary-20-color;
      }
    }

    &:hover {
      outline: solid 1px $secondary-30-color;
      border: none;
    }

    :focus-within {
      outline: none;
      border: none;
      background-color: $secondary-20-color;
    }

    &__error {
      color: $status-high-100-color;
      margin-top: 3px;
      bottom: -20px;
      left: 10px;
      font-size: 0.7em;
    }
  }
}
