@import "../../sass/colors.scss";

.incidentsSeverityCategoryContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &__icon {
    width: 16px;
    margin-right: 0.5em;
  }
}

.incidentsSeverityBar {
  width: 1em;
  height: 0.5em;
  margin-right: 0.5em;

  &--critical {
    background-color: $status-critical-100-color;
  }

  &--high {
    background-color: $status-high-100-color;
  }

  &--medium {
    background-color: $status-medium-100-color;
  }

  &--low {
    background-color: $status-low-100-color;
  }

  &--informational {
    background-color: $status-success-100-color;
  }

  &--neutral {
    background-color: $secondary-40-color;
  }
}

.incidentsRules {
  position: relative;
  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;

    &__item {
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0em;
      }
    }
  }
}

.incidentsAssigned {
  &__anchor {
    position: relative;
    width: 100%;
  }

  &__assign-tooltip {
    position: relative;
    top: -1.5em;
    left: -4em;

    &__text {
      white-space: nowrap;
    }
  }

  &__handler-selector {
    width: fit-content;
    margin: 0 auto;
    position: relative;
  }
}
