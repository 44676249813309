@import "../sass/colors.scss";

.sensorsListContainer {
  display: flex;
  flex-direction: column;
  background-color: $secondary-05-color;

  &__header-container {
    display: flex;
    align-items: center;
    .sensors-count {
      color: $secondary-50-color;
    }
    &__spinner {
      width: 30px;
      margin-bottom: -8px;
      margin-left: 10px;
    }
    &__loading-text {
      color: $secondary-40-color;
    }
  }
  
  .sensorsSync {
    position: relative;
    img {
      cursor: pointer;
    }
  }

  &__actionBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1.5em 1em;

    .sensorsFilters {
      margin-left: 1.3em;
    }

    .sensorsFilters,
    .sensorsDownload {
      cursor: pointer;
      margin-right: 1.3em;
      position: relative;
    }

    &__org {
      width: 16em;
    }
  }

  &__info {
    margin: 0 1em;
    overflow: auto;
    max-height: calc(100vh - 360px);
    min-height: 500px;
  }
}
