@import "../../sass/colors.scss";

.incidentContainer__details {
  &__header {
    grid-area: incidentContainer__details__header;
    flex-direction: row;
    align-items: flex-start;
    margin: 1em 1em 0 1em;
    padding-bottom: 2em;

    & > :nth-child(2) {
      margin-left: auto;
      cursor: pointer;
      width: 1.5em;
    }
  }

  &__created {
    grid-area: incidentContainer__details__created;
    margin: 1em 0 0 1em;
  }

  &__status {
    grid-area: incidentContainer__details__status;
    margin: 1em 1em 0 0;
    padding-left: 2em;
    padding-bottom: 1.5em;
  }

  &__category {
    grid-area: incidentContainer__details__category;
    margin: 1em 0 0 1em;
    padding-bottom: 1.5em;
  }

  &__category {
    &__anchor {
      display: flex;
      flex-direction: row;
      width: 100%;

      &__risk {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &__icon {
          margin-right: 1em;

          & svg {
            width: 1em;
            height: 1em;
          }

          &--black path {
            fill: $secondary-100-color;
          }

          &--white path {
            fill: $secondary-00-color;
          }
        }
      }

      &__option {
        position: relative;
      }
    }
  }

  &__icon {
    margin-top: 1em;

    & svg {
      width: 1.5em;
      height: 1.5em;
    }

    & img {
      width: 1.5em;
    }

    &--black,
    &--white {
      margin-right: 1em;
    }

    &--black path {
      fill: $secondary-100-color;
    }

    &--white path {
      fill: $secondary-00-color;
    }
  }

  &__severity {
    grid-area: incidentContainer__details__severity;
    padding-left: 2em;
    margin: 1em 1em 0 0;

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & img {
        margin-right: 1em;
      }
    }
  }

  &__hostname {
    grid-area: incidentContainer__details__hostname;
    margin: 1em 0 1em 1em;
    overflow-wrap: anywhere;
  }

  &__username {
    grid-area: incidentContainer__details__username;
    padding-left: 2em;
    margin: 1em 1em 0 0;
  }

  &__title {
    grid-area: incidentContainer__details__title;
    margin: 1em 1em 1em 1em;
  }

  &__description {
    grid-area: incidentContainer__details__description;
    margin: 1em 1em 0 1em;
  }

  &__tags {
    grid-area: incidentContainer__details__tags;
    margin: 1em 1em 0 1em;
    margin-bottom: 30px;

    &__container {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
  }

  &__save {
    grid-area: incidentContainer__details__save;
    margin: 1em 1em 0 1em;
    margin-bottom: 30px;

    &__container {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
  }

  &__section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &--row {
      flex-direction: row;
    }

    &--column {
      flex-direction: column;
    }

    &--border-bottom {
      border-bottom: 1px solid $secondary-30-color;
    }

    & label {
      margin-bottom: 0.5em;
    }
  }

  &__tooltip {
    position: relative;
    left: 1em;

    &__text {
      white-space: nowrap;
    }
  }
}

.incidentContainer__division {
  &__header {
    grid-area: incidentContainer__division__header;
  }

  &__status {
    grid-area: incidentContainer__division__status;
  }

  &__category {
    grid-area: incidentContainer__division__category;
  }

  &__severity {
    grid-area: incidentContainer__division__severity;
  }

  &__username {
    grid-area: incidentContainer__division__username;
  }

  &__title {
    grid-area: incidentContainer__division__title;
  }

  &__description {
    grid-area: incidentContainer__division__description;
  }

  &__tags {
    grid-area: incidentContainer__division__tags;
  }

  &__save {
    grid-area: incidentContainer__division__save;
  }

  &__paragraph {
    white-space: pre-wrap;
  }

  &__more {
    grid-area: incidentContainer__division__more;
  }

  &__header,
  &__status,
  &__category,
  &__severity,
  &__username,
  &__title,
  &__description,
  &__tags,
  &__save,
  &__more {
    width: 1px;
    background-color: $secondary-30-color;
  }
}
