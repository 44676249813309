.organizationsContainer {
  display: flex;
  flex-direction: column;

  &__actionBar {
    margin-top: 1.5em;
    margin-left: auto;
    margin-right: 1em;

    &__actionBarContainer__divider {
      margin-left: 1.2em;
      margin-right: 1.2em;
    }
  }

  &__list {
    margin-top: 4em;
    padding-left: 1em;
    padding-right: 1em;
  }
}

.organizationsActionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;

  &__divider {
    margin: 0 0.5em;
  }

  &__more {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &__icon {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }

  &__more,
  &__edit {
    position: relative;
  }
}
