@import "../../sass/colors.scss";

.chipContainer {
  padding: 0.5em 0.8em;
  width: -moz-fit-content;
  width: fit-content;

  &--oval {
    border-radius: 2em;
  }

  &--circle {
    width: 2em;
    height: 2em;
    border-radius: 50%;
  }

  &--statusNew,
  &--statusExpired {
    background-color: $secondary-20-color;
  }

  &--statusAssigned {
    background-color: $status-active-30-color;
  }

  &--statusReview,
  &--statusPending {
    background-color: $status-low-100-color;
  }

  &--statusSent,
  &--statusViewed,
  &--statusAck {
    background-color: $secondary-40-color;
  }

  &--statusClosed,
  &--statusOnline {
    background-color: $status-success-80-color;
  }

  &--statusIsolated {
    background-color: $status-medium-100-color;
  }

  &--statusMore {
    background-color: $secondary-30-color;
  }

  &--statusDefault,
  &--statusOffline {
    background-color: $secondary-10-color;
  }
}
