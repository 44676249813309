@import "../../sass/colors.scss";

.loginAnimationContainer{
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 34% 66%;
    padding: 1em 1em;
    background-color: $secondary-05-color;
}

.loginAnimationInfo{
    position:relative;
    padding-left: 0.4em;
    border-left: 1px solid $secondary-50-color;
    display: flex;
    flex-direction: column;
}

.loginAnimationStatus{
    margin-top: 0.7em;
    color: $primary-dark-120-color;
}

.loader-container{
    width: 10%;
    margin: 0 auto;
    flex-basis: 80%;
}
