@import "../../sass/colors.scss";

.incidentCloseContainer{
    max-width: 444px;
    width: 41%;
    border: none;
    background-color: $secondary-05-color;
}

.incidentsCloseContainer__status{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;
    padding-top: 1em;
    border-top: 1px solid $secondary-30-color;
}
/*Not available in Safari https://caniuse.com/mdn-css_types_resolution_dpcm*/
@media all and (min-resolution: .001dpcm){

    .incidentsCloseContainer__status__input {
        -moz-appearance: none;
        appearance: none;
        width: 1em;
        height: 1em;
        cursor: pointer;
        border: 1px solid $secondary-100-color;
        border-radius: 50%;
        box-shadow: 0 0 0 1px $secondary-100-color;
    }

    .incidentsCloseContainer__status__input:checked {
        -moz-appearance: none;
        appearance: none;
        width: calc(1em - 1px);
        height: calc(1em - 1px);
        border: 4px solid $secondary-05-color;
        background-color: $accent-120-color;
        box-shadow: 0 0 0 2px $accent-120-color;
    }
}

@media not all and (min-resolution: .001dpcm) {

    .incidentsCloseContainer__status__input {
        -webkit-appearance: none;
        cursor: pointer;
        border: 1px solid $secondary-100-color;
        border-radius: 50%;
        box-shadow: 0 0 0 1px $secondary-100-color;
    }

    .incidentsCloseContainer__status__input:checked {
        -webkit-appearance: none;
        border: 4px solid $secondary-05-color;
        background-color: $accent-120-color;
        box-shadow: 0 0 0 2px $accent-120-color;
    }
}

.incidentsCloseContainer__divider{
    margin-top: 2em;
    border-top: 1px solid $secondary-30-color;
}
