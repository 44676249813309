@import "../../../sass/colors.scss";

.addEventContainer{
    max-width: 444px;
    width: 41%;
    border: none;
    background-color: $secondary-05-color;
}

.addEventContainer__title{
    margin-bottom: 1.5em;
}