@import "../../sass/colors.scss";

.navBar {
  &__brand-logo {
    cursor: pointer;
    height: 33px;
  }

  &Container {
    display: flex;
    flex-direction: row;
    background-color: $secondary-05-color;
    padding: 1em 1em 1em;
    width: 100%;
  }

  &Actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: auto;

    .navBarDashboard,
    .navBarIncidents,
    .navBarOrganizations,
    .navBarUsers,
    .navBarSensors,
    .navBarPantherEvents {
      cursor: pointer;
      position: relative;
    }

    .navBarIncidents,
    .navBarOrganizations,
    .navBarUsers,
    .navBarSensors,
    .navBarDivider,
    .navBarPantherEvents {
      margin-left: 1em;
    }
    .navBarIncidents,
    .navBarDashboard,
    .navBarOrganizations,
    .navBarUsers,
    .navBarSensors,
    .navBarPantherEvents {
      &.active {
        & path {
          fill: $accent-new-100-color;
        }

        &:hover path {
          fill: $accent-new-120-color;
        }
      }
    }
  }
}
