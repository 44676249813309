@import "../../../sass/colors.scss";

.paginationContainer{
    margin-top: 1em;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .paginationSelect{
        width: 65px;
        margin-left: 1em;
        border: none;
        font-family: 'basier_squareregular';
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        background-color: $secondary-05-color;
        color:$secondary-100-color;
        cursor: pointer;
    }

    .paginationDivider{
        margin-left: 1.8em;
    }

    .paginationPrevious, .paginationNext {
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &--disabled{
            cursor: not-allowed;

            path{
                fill: $secondary-40-color;
            }
        }
    }

    .paginationPrevious{
        margin-left: 1.5em;
    }

    .paginationNext{
        margin-left: 2em;
        margin-right: 2em;
    }
}
