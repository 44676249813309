@import "../../sass/colors.scss";

.tabs {

  &-nav {
    display: flex;
    gap: 40px;
    list-style-type: none;
    margin-bottom: 2em;
    font-size: 14px;

    li {
      color: $secondary-50-color;
      font-weight: 600;
      cursor: pointer;
    }

    li.active {
      color: $secondary-100-color;
    }
  }

  &-outlet {

  }
}

