@import "../../sass/colors.scss";

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.8em 1em 1.8em 1em;
  border-bottom: solid 1px $secondary-50-color;
  width: 100%;

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0.5em;

    &__subTitle {
      color: $secondary-60-color;
    }
  }
}
