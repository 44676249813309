@import "../../sass/colors.scss";

.tableContainer{
    width: 100%;
}

.table-section {
    position: relative;
}

table{
    border-collapse: collapse;
    position: relative;
}

.tableHeader {
    border-bottom: solid 1px $secondary-30-color;
}

.tableHeaderData{
    padding: 1em 1em;
    text-align: left;
}

.tableHeaderData--visible {
    opacity: 1;
}

.tableHeaderData--hidden {
    display: none;
}

.tableHeaderData--onDemand {
    opacity: 0;
}

.tableRow{
    border-bottom: solid 1px $secondary-30-color;
}

.tableCell{
    padding: 0.5em 1em;
    font-size: 13px;
}

.tableCell--visible {
    opacity: 1;
}

.tableCell--hidden {
    display: none;
}

.tableCell--onDemand {
    opacity: 0;
}

.tableCellDivider{
    margin-left: 1em;
    margin-right: 1em
}

.tableFilterContainer{
    margin-bottom:2em;
}

.loader-container, .no-data-container {
    position: absolute;
    top: 100px;
}

.loader-container {
    left: 45%;
}

.no-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    left: calc(50% - 200px);
}

.no-data-container>.no-data-container__image{
    width: 50px;
    margin-bottom: 10px;
}

.no-data-container>.no-data-container__title, .no-data-container>.no-data-container__description {
    text-align: center;
    font-size: 16px;
    line-height: 19px;
}

.no-data-container>.no-data-container__title {
    color: $secondary-120-color;
    margin-bottom: 10px;
    font-weight: 500;
}

.no-data-container>.no-data-container__description {
    text-align: center;
    color: $secondary-60-color;
    font-weight: 400;
}
