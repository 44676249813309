.osField,
.typeField {
  position: relative;
  width: fit-content;
}

.tags {
  display: flex;
  gap: 5px;
  position: relative;
  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;

    &__item {
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0em;
      }
    }
  }
}

.last-connection-header {
  display: flex;

  &__sort {
    margin-left: 10px;
    width: 15px;
    cursor: pointer;

    &-inverted {
      transform: rotate(180deg);
    }
  }
}
