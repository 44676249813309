@import "../../sass/colors.scss";

.activityComments__title label {
    color: $secondary-50-color;
}

.activityComments__actions{
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}

.activityCommentsDeleteConfirmation{
    max-width: 444px;
    width: 41%;
    border: none;
    background-color: $secondary-05-color;
}
