@import "../../sass/colors.scss";

.incidentsfilters__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 1em 2em;
  padding-top: 1.5em;
  border-top: solid 1px $secondary-50-color;

  &--hide {
    display: none;
  }

  .filters {
    display: flex;
    flex-direction: column;
    column-gap: 1.5em;
    row-gap: 2em;
    flex-basis: 90%;

    .filterRow,
    .secondRow,
    .dateFilters {
      display: flex;
    }

    .filterRow {
      column-gap: 1.5em;

      .clientFilter, .hostnameFilter, .statusFilter, .categoryFilter, .ruleFilter, .escalatedFilter {
        flex-basis: calc(25% - 1.2em);
      }
    }

    .dateFilters {

      align-items: flex-end;
      min-width: 650.54px;
      margin-right: 20px;

      .createStartDateFilter, .createEndDateFilter {
        flex-basis: calc(51.77% - 1.5em);
        z-index: 1;
      }

      &__divider {
        background-color: $secondary-10-color;
        width: 25px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-symbol {
          width: 12px;
          height: 2px;
          background-color: $secondary-50-color;
        }
      }
    }
  }

  .filterActions {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin-top: 15px;
    flex-basis: 10%;
  }
}
