@import "../../sass/colors.scss";

.avatarContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    font-size: 1em;
    color:$secondary-10-color;
}

.avatarContainer--disabled:hover{
    cursor: default;
}

.avatarContainer--enabled:hover{
    cursor: pointer;
}

.avatarContainer--default{
    background-color: $secondary-100-color;    
}

.avatarContainer--default-secondary{
    background-color: $secondary-80-color;    
}

.avatarContainer--active{
    background-color: $accent-new-100-color;
}

.avatarContainer>.iconContainer {
    width: 100%;
}
