/*Colors from Design System palette https://www.figma.com/file/7mieTU49bblcdOshoCuUMW/2020_012_SOTERIA_Product_editor?node-id=267%3A0*/
$primary-dark-120-color:#001D2F;
$accent-100-color:#76C9D7;
$accent-120-color:#2E9FB2;
$primary-150-color: #0C889D;
$primary-accent-120-color: #2E9FB2;
$secondary-100-color:#00263E;
$secondary-120-color: #001726;
$secondary-100-color-disabled:rgba(0,38,62,0.3);
$secondary-80-color: #2C4757;
$secondary-60-color: #5D8297;  
$secondary-50-color: #83A6B5;
$secondary-40-color: #A7C4CF;
$secondary-30-color:#CEDDE3;
$secondary-20-color:#DDE6EA;
$secondary-10-color: #EDF2F4;
$secondary-05-color: #F7F9FA;
$secondary-05-color-transparent: rgba(247,249,250,0);
$secondary-00-color: white;
$status-active-100-color: #41A0FE;
$status-active-30-color: #B8DCFF;
$status-critical-100-color: #FF3333;
$status-high-100-color: #FF7474;
$status-medium-100-color: #FFAE74;
$status-low-100-color: #FFE380;
$status-success-80-color: #9AECBF;
$status-success-100-color: #80E5A9;
$menu-divider-color: rgba(255,255,255,.2);
$login-button-disabled: #c4c4c4;
$accent-new-100-color: #4290EB;
$accent-new-10-color: #EBF4FC;
$accent-new-120-color: #3278BF;