@import "../../sass/colors.scss";

.select-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.125em;
  padding: 0.5em;
  background-color: $secondary-10-color;
  height: 2.25em;
  position: relative;

  &__selected-option {
    text-overflow: ellipsis;
    flex-basis: 90%;
    overflow: hidden;
    white-space: nowrap;

    &__search {
      width: 100%;
      border: none;
      outline: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 2.25em;
      padding: 0.5em 15px;
      background: transparent;
      cursor: pointer;

      &:focus {
        cursor: text;
      }

      &:disabled {
        color: $secondary-100-color-disabled;
      }
    }
  }

  &__chevron {
    display: flex;
    margin-left: 5px;
  }

  &.select-container--noBackground {
    background: none;
  }

  &.select-container--disabled {
    color: $secondary-100-color-disabled;
    cursor: not-allowed;
    outline: none;

    .select-container__chevron {
      display: none;
    }

    :not(&).select-container:hover {
      outline: solid 1px $secondary-30-color;
      border: none;
      cursor: pointer;
    }
  }

  &.select-container--error {
    outline: solid 1px $status-high-100-color;
  }
}
