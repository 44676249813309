@import "../sass/colors.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  & > .prompt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 400px;
    height: 540px;
    background-color: $secondary-00-color;
    border-radius: 5px;
    box-shadow: 0 12px 40px rgb(0 0 0 / 12%);
    padding: 40px;

    & > .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 24px 0 32px;

      & > .logo {
        width: 67px;
        height: 52px;
      }

      & > .title {
        font-size: 25px;
        margin: 24px 0 16px;
      }

      & > .message {
        font-size: 14px;
        margin-top: 10px;
      }
    }

    & > .content {
      margin-bottom: 40px;

      & > .button {
        width: 100%;
        margin-top: 35px;
        min-height: 52px;
        border-radius: 3px;
      }
    }
  }
}
