@import "../../sass/colors.scss";

.warning-banner-container {
  grid-area: incidentContainer__banner;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  background-color: $secondary-10-color;
  border-bottom: solid 1px $secondary-50-color;
  padding: 15px;

  &__icon {
    margin-right: 10px;
    width: 25px;
  }

  &__text,
  &__bold {
    font-size: 14px;
  }

  &__bold {
    font-weight: bold;
    margin-right: 7px;
  }
}
