@import "../../sass/colors.scss";

.incidentContainer__header{
    grid-area: incidentContainer__header;
}

.incidentContainer__separator {
    margin-left: 0.5em;
    margin-right: 0.5em;
}
.navLink {
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    text-decoration: none;
    color: $secondary-100-color;
}

.navLink--active {
    font-family: 'basier_squaremedium',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.navLink--inactive {
    font-family: 'basier_squareregular',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.incidentContainer__header__actionBar__select__divider{
    height: 1px;
    width: calc(100% - 1.4em);
    margin: 4px 0.7em;
    background-color: $menu-divider-color;
}

.incidentContainer__header__actionBar__divider{
    margin-left: 1em;
    margin-right: 1em;
}

.incidentContainer__header__actionBar__previous{
    margin-right: 3em;
    cursor: pointer;
}

.incidentContainer__header__actionBar__next{
    cursor: pointer;
}

.incidentContainer__header__actionBar__previous--disabled, .incidentContainer__header__actionBar__next--disabled {
    cursor: not-allowed;
}

.incidentContainer__header__actionBar__previous--loading, .incidentContainer__header__actionBar__next--loading {
    cursor: progress;
}

.incidentContainer__header__actionBar__previous--disabled path, .incidentContainer__header__actionBar__next--disabled path{
    fill: $secondary-40-color;
}
