@import "../sass/colors.scss";

.incidentsContainer {
  display: flex;
  flex-direction: column;
  background-color: $secondary-05-color;
  height: 100vh;

  .incidentsSync {
    position: relative;
    img {
      cursor: pointer;
    }
  }

  &__actionBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 1.5em 1em;

    .incidentsFilter {
      margin-left: 1.3em;
    }

    .incidentsFilter,
    .incidentsDownload {
      margin-right: 1.3em;
      position: relative;
    }

    .incidentsFilter,
    .incidentsDownload {
      cursor: pointer;
    }

    &__bulk-actions {
      width: 16em;
    }
  }

  &__info {
    margin: 0 1em;
  }
}
