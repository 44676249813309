@import "../../sass/colors.scss";

.eventDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.eventDetailsContainer__title_holder {
  max-width: 60%;
  a {
    word-break: break-all;
  }
}

.eventDetailsContainer__separator {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.eventDetailsContainer__actions-copy,
.eventDetailsContainer__actions-edrView {
  width: 1em;
  height: 1em;
  cursor: pointer;
}

.eventDetailsContainer__actions-copy img,
.eventDetailsContainer__actions-edrView img {
  width: 1em;
  height: 1em;
}

.eventDetailsContainer__actions-copy {
  margin-right: 1em;
}

.eventDetailsContainer__details {
  height: 100%;
  width: 100%;
}

.eventDetailsContainer__details > h5 {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 1em;
}

.eventDetailsContainer__data {
  height: 100%;
  width: 100%;
  padding: 1em;
  background-color: $secondary-10-color;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.eventDetails__brace {
  color: $secondary-60-color;
}

.eventDetails__number {
  color: $status-high-100-color;
}

.eventDetails__ip {
  color: $primary-150-color;
}

.eventDetails__filePath {
  color: $primary-150-color;
}
