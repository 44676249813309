@import "../sass/colors.scss";

.eventDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  p.section-title {
    font-weight: bolder;
    font-size: 16px;
  }
  .accordionContainer {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.eventContainer {
  display: grid;
  grid-template-columns: 2em 1fr;
  grid-template-areas:
    "eventContainerColumn   eventContainerDivider"
    "eventContainerColumn   eventContainerDetails"
    "eventContainerColumn   eventContainerChildren"
    "eventContainerColumn   eventContainerAnalystNotes";
  column-gap: 1em;
}

.eventContainerColumn {
  grid-area: eventContainerColumn;
  grid-row: span 4;
  justify-self: center;
  background-color: $secondary-50-color;
  width: 1px;
  height: 100%;
}

.eventContainer__divider {
  margin-top: 1em;
  grid-area: eventContainerDivider;
  height: 1px;
  background-color: $secondary-30-color;
}

.eventContainer__children {
  grid-area: eventContainerChildren;
}

.eventContainer__details {
  grid-area: eventContainerDetails;
}

.eventContainer__analystNotes {
  grid-area: eventContainerAnalystNotes;
  margin-top: 15px;
}

.eventContainer__header {
  grid-area: eventContainerHeader;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.eventContainer__title label {
  color: $secondary-50-color;
}

.genericEventDetailsContainer {
  width: 100%;
  margin-top: 5px;
  p.section-title {
    font-weight: bolder;
    font-size: 16px;
  }

  label {
    font-size: 16px;
    font-family: "basier_squaremedium", -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
  }

  .genericEventDetailsContainerList {
    list-style-type: none;
    margin-top: 5px;
    margin-bottom: 20px;
    li {
      font-size: 14px;
    }
    span {
      word-break: break-word;
    }
    img {
      margin-right: 10px;
      width: 1em;
      height: 1em;
      opacity: 0.5;
    }
    &--alerts {
      max-width: 95%;
    }
    .panther-event-detail-list {
      font-size: 14px;
      margin-bottom: 15px;
      display: flex;
      max-width: max-content;
      padding: 2px 5px;
      &--enable:hover {
        cursor: pointer;
        background-color: $secondary-20-color;
      }
    }
  }
}

.severity-event {
  &-info-modal {
    display: flex;
    align-items: center;
  }
  &-info-container {
    max-height: 400px;
    overflow-y: auto;
  }
}
