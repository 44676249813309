@import "../sass/colors.scss";

.usersContainer {
  display: flex;
  flex-direction: column;
  background-color: $secondary-05-color;

  &__actionBar {
    margin-top: 1.5em;
    margin-left: auto;
    margin-right: 1em;
    z-index: 1;
  }

  &__actionBarContainer__divider {
    margin-left: 1.2em;
    margin-right: 1.2em;
  }

  .usersInfo {
    display: flex;
    flex-direction: column;
    margin-top: -30px;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .usersActionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;

    &__divider {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }

    &__delete {
      img {
        margin-left: 0.2em;
        margin-right: 0.2em;
      }
    }

    &__delete,
    &__edit {
      position: relative;
    }
  }
}

.userDeletedIcon path {
  fill: $secondary-10-color;
}
