@import "../../sass/colors.scss";

.incidentContainer__activity__timeline {
  margin: 1em 1em 1em 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.incidentContainer__activity__timeline > :nth-child(1) {
  margin-top: 1em;
}

.incidentContainer__activity__timeline__date {
  margin-top: 1.5em;
  width: 100%;
}

.incidentContainer__activity__accordion__icon {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: $secondary-100-color;
}

.incidentContainer__activity__accordion__title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.incidentContainer__activity__accordion__title span {
  color: $secondary-50-color;
  margin-left: 1em;
}

.incidentContainer__activity__timeline__comment {
  margin-top: 1em;
}

.incidentContainer__activity__timeline__event {
  margin-top: 0.5em;
}

.incidentContainer__activity__timeline__filters {
  width: 16em;
}

.incidentContainer__activity__timeline__load-more {
  width: 100%;

  &__button {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
}
