.filterContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    label {
        margin-bottom: 0.5em;
    }
}
